import {motion} from 'framer-motion';
import {HTMLAttributes, useEffect, useState} from 'react';
import {Duration, DurationUnit, intervalToDuration, isBefore} from 'date-fns';
import {cn} from '~/utils';

type CountdownProps = {
  endDate?: Date;
} & HTMLAttributes<HTMLDivElement>;

export const Countdown = ({
  endDate = new Date(),
  className,
  children,
}: CountdownProps) => {
  const [duration, setDuration] = useState<Duration>(() =>
    intervalToDuration({start: new Date(), end: endDate})
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const duration = intervalToDuration({start: now, end: endDate});

      if (isBefore(endDate, now)) {
        clearInterval(interval);
      } else {
        setDuration(duration);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const unitsToDisplay: DurationUnit[] = [
    'days',
    'hours',
    'minutes',
    'seconds',
  ];

  return (
    <div
      className={cn(
        'flex items-baseline flex-wrap gap-x-6 md:gap-x-8',
        className
      )}
    >
      {unitsToDisplay.map(unit => {
        const value = duration[unit];
        return (
          <div className="flex flex-col items-center" key={unit}>
            <div className="relative flex items-baseline gap-2 text-3xl tabular-nums md:flex-col md:gap-0">
              <div className="font-bold opacity-0">{formatNumber(value)}</div>
              <motion.div
                key={formatNumber(value)}
                animate={{
                  scale: 1,
                  transition: {
                    ease: 'easeOut',
                    duration: 0.3,
                  },
                }}
                initial={{scale: 1.5}}
                className={cn(
                  'absolute top-0 font-bold lining-nums text-foreground md:right-0 md:text-right'
                )}
              >
                {formatNumber(value)}
              </motion.div>
            </div>
            <div>{unit}</div>
          </div>
        );
      })}
      {children}
    </div>
  );
};

const formatNumber = (number: number | undefined) => {
  return (number ?? 0)?.toLocaleString('en-CH', {
    minimumIntegerDigits: 2,
  });
};
